/* NavbarComp */
.navbar{
  min-height: 10vh;
  font-size: 25px !important;
}
.brand{
  color: blueviolet !important;
  font-size: 35px !important;
}

/* Home */
.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.masthead h1, .masthead .h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
          background-clip: text;
}
.masthead h2, .masthead .h2 {
  max-width: 20rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .masthead h1, .masthead .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (min-width: 992px) {
  .masthead {
    height: 85vh;
    padding: 0;
  }
  .masthead h1, .masthead .h1 {
    font-size: 6.5rem;
    line-height: 6.5rem;
    letter-spacing: 0.8rem;
  }
  .masthead h2, .masthead .h2 {
    max-width: 30rem;
    font-size: 1.25rem;
  }
}

/* Questions */
.queshead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.queshead h1, .queshead .h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
          background-clip: text;
}
.queshead h2, .queshead .h2 {
  max-width: 20rem;
  font-size: 1rem;
}
.container{
    margin-top: 30px;
}
.title{
    margin-left: 5px;  
}
.continue-button{
    min-width: 20%;
    width: 70%;
    margin-top: 10px
}
.card{
    width: auto;
}
@media (min-width: 768px) {
  .queshead h1, .queshead .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (min-width: 992px) {
  .queshead {
    height: 10vh;
    padding: 0;
  }
  .queshead h1, .queshead .h1 {
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: 0.8rem;
  }
  .queshead h2, .queshead .h2 {
    max-width: 30rem;
    font-size: 1.25rem;
  }
}

/* Result */
.resuhead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 15rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.resuhead h1, .resuhead .h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
          background-clip: text;
}
.resuhead h2, .resuhead .h2 {
  max-width: 20rem;
  font-size: 2.0rem;
}
.resuhead h3, .resuhead .h3 {
  max-width: 20rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .resuhead h1, .resuhead .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
}
@media (min-width: 992px) {
  .resuhead {
    height: 10vh;
    padding: 0;
  }
  .resuhead h1, .resuhead .h1 {
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: 0.8rem;
  }
  .resuhead h2, .resuhead .h2 {
    max-width: 30rem;
    font-size: 1.25rem;
  }
}
